import React from "react";
import {takeLatest, call, put} from "redux-saga/effects";
import Actions from "@actions";
import Api from "../../Api";
import {PayloadAction} from "@reduxjs/toolkit";
import {iActions} from "@redux/Anonymize/types";
import useNotification from "@root/Hooks/useNotification/useNotification";
import {iParams} from "@root/Api/Anonymize/types";
import {Api as ApiTypes} from "@types";

export const getAnonHtmlRequest = function* (params: iParams.iGetAnonHtml) {
    try {
        put(Actions.HTML.setIsFrameLoaded(false));
        const {data} = yield call(Api.Anonymize.getAnonHtml, params);
        return data;
    } catch (ex) {
        return null;
    }
};

const GetAnonHtml = function* (action: PayloadAction<iActions.getAnonHtml>) {
    type iData = ApiTypes.UnboxAxios<ApiTypes.UnboxPromise<ReturnType<typeof Api.Anonymize.getAnonHtml>>> | null;
    const {payload} = action;
    const notification = useNotification();

    try {
        const data: iData = yield call(getAnonHtmlRequest, {docId: payload.docId});
        if (data) yield put(Actions.Anonymize._getAnonHtmlSuccess(data));
    } catch (error: any) {
        yield put(Actions.Anonymize._getAnonHtmlError());
        notification({
            type: "error",
            message: error.response.data.error 
                ? error.response.data.error 
                : "При получении HTML документа произошла ошибка"
        });
    }
};

export default function* () {
    yield takeLatest(Actions.Anonymize.getAnonHtml, GetAnonHtml);
}
