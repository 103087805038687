import React from "react";
import {useAppSelector} from "@root/Hooks";
import Loading from "@components/Loading/Loading";
import AnonymizeContent from "../Contents/Anonymization/Anonymization";
import {Redirect} from "react-router-dom";
import routes from "@root/Routes/Routes";
import Error from "@modules/Error/Error";

const ContentController = () => {
    const anonymizeState = useAppSelector((state) => state.Anonymize.anonymizeProcess);
    const anonymizeHtml = useAppSelector((state) => state.Anonymize.anonymizedHtml);
    const anonymizeStatus = useAppSelector((state) => state.Anonymize.anonymizedHtml.data?.anonymization_status);
    const documentFetching = useAppSelector((state) => state.Project.openedDocument.fetching);
    const anonType = useAppSelector((state) => state.Anonymize.anonymizedHtml.data?.anonymization_type);
    const coloredPdf = useAppSelector((state) => state.Anonymize.coloredPdf.file);
    const openedDocumentProjectID = useAppSelector((state) => state.Project.openedDocument.data?.project_id);
    const openedDocumentID = useAppSelector((state) => state.Project.openedDocument.data?.id);
    const AnonHtmlNoHas = anonType !== "pdf_anonymization" && !anonymizeHtml;

    if (documentFetching) {
        return (
            <Loading>
                Загрузка информации о документе...
            </Loading>
        );
    }
    if (anonymizeStatus !== undefined) {
        if (anonymizeHtml.data?.anonymized_html === "") {
            return (
                <Loading>
                    Выполняется анонимизация. <br />
                    Пожалуйста, подождите...
                </Loading>
            );
        } 
        if (anonymizeStatus === 400) {
            return (
                <Error>
                    Произошла ошибка при анонимизации
                </Error>
            );
        }
        if (anonymizeStatus === 100) {
            return (
                <Loading>
                    Выполняется анонимизация. <br />
                    Пожалуйста, подождите...
                </Loading>
            );
        }
        if (anonymizeStatus ===  200) {
            if (anonType === "pdf_anonymization") {
                if (!coloredPdf.loaded && !coloredPdf.error) {
                    return (
                        <Loading>
                            Выполняется загрузка PDF файла. <br />
                            Пожалуйста, подождите...
                        </Loading>
                    );
                } else if (coloredPdf.loaded) {
                    return <AnonymizeContent />;
                } else if (openedDocumentProjectID && openedDocumentID) {
                    return <Redirect to={routes.lk.project.document(
                        openedDocumentProjectID,
                        openedDocumentID)} />;
                }
            } else {
                if (!anonymizeHtml.loaded && !anonymizeHtml.error) {
                    return (
                        <Loading>
                            Выполняется загрузка HTML файла. <br />
                            Пожалуйста, подождите...
                        </Loading>
                    );
                } else if (anonymizeHtml.loaded) {
                    return <AnonymizeContent />;
                } else {
                    return <Redirect to={routes.lk.project.document(
                        openedDocumentProjectID,
                        openedDocumentID)} />;
                }
            }
        }
    }
    if (anonymizeState.error || AnonHtmlNoHas) {
        return (
            <Error>
                Произошла ошибка при загрузке информации о документе.
            </Error>
        );
    }
    return null;
};

export default ContentController;
