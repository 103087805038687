// TODO КОГДА БУДЕТ ГОТОВА ЛОГИКА АНОНИМИЗАЦИИ ПРОВЕРИТЬ ЗНАЧЕНИЯ PARAMS
import React, {useEffect} from "react";
import styles from "./Anonymization.module.less";
import {usePageParams} from "./Hooks/useParams";
import {useAppDispatch, useAppSelector} from "@root/Hooks";
import Actions from "@actions";
import ContentController from "./Controllers/ContentController";
import {useHistory} from "react-router-dom";
import routes from "@root/Routes/Routes";
import useTitle from "@root/Hooks/useTitle/useTitle";

const Anonymization = () => {
    const params = usePageParams();
    const dispatch = useAppDispatch();
    const data = useAppSelector((state) => state.Anonymize.anonymizedHtml.data);
    const history = useHistory();
    useTitle("DeepDocs - Анонимизация");
    
    useEffect(() => {
        const docNum = params.documentID;
        const anonymizationID = params.anonymizationID;
        if (!docNum) return;
        if (!anonymizationID) return;
        dispatch(Actions.Project.getDocumentInfo({
            id: docNum
        }));
        dispatch(Actions.Anonymize.getAnonHtml({
            docId: anonymizationID
        }));
        dispatch(Actions.Anonymize.initAnonymizedDoc({
            id: docNum
        }));
        return () => {
            dispatch(Actions.Anonymize.anonymizeWatchStop());
            dispatch(Actions.Common.setScrollPosition(null));
        };
    }, []);

    useEffect(() => {
        if (!data) return;
        const docId = params.documentID;
        const projectId = params.projectID;
        const anonymizationID = params.anonymizationID;
        if (!docId) return;
        if (!projectId) return;

        if (data.anonymization_status >= 200) return;
        if (data.anonymization_status === 0) {
            history.push(routes.lk.project.document(projectId, docId));
        }
        if (data.anonymization_status === 100) {
            dispatch(Actions.Anonymize.anonymizeWatchStart({
                docId: anonymizationID
            }));
        }
    }, [data]);

    return (
        <div className={styles.wrapper}>
            <ContentController />
        </div>
    );
};

export default Anonymization;
