import React, {useState} from "react";
import ViewModeSwitcher from "./Modules/ViewModeSwitcher/ViewModeSwitcher";
import {useAppSelector, useAppDispatch, useNotification} from "@root/Hooks";
import Actions from "@actions";
import downloadPdf from "./Utils/downloadPdf/downloadPdf";
import BackLink from "./Modules/BackLink/BackLink";
import useDocument from "../../Hooks/useDocument";
import Toolbar from "@root/Components/Toolbar/Toolbar";
import DropdownMenu from "./Modules/DropdownMenu/DropdownMenu";
import styles from "./TopToolbar.module.less";

const TopToolbar = () => {
    const dispatch = useAppDispatch();
    const openedDocument = useAppSelector((state) => state.Project.openedDocument.data);
    const coloredPdf = useAppSelector((state) => state.Anonymize.coloredPdf);
    const selectedMarkups = useAppSelector((state) => state.HTML.selectedMarkups);
    const addedEntities = useAppSelector((state) => state.HTML.entities.newEntities);
    const anonymizedHtml = useAppSelector((state) => state.Anonymize.anonymizedHtml.data);
    const documentState = useDocument();
    const notification = useNotification();
    const [loading, setLoading] = useState(false);

    if (!openedDocument || !documentState || !anonymizedHtml) return null;

    const downloadResult = () => {
        setLoading(true);
        setTimeout( async () => {
            try {
                if (openedDocument) {
                    if (openedDocument.anonymization_type === "pdf_anonymization") {
                        await downloadPdf(coloredPdf.fileName);
                        setLoading(false);
                    } else {
                        dispatch(Actions.Anonymize.downloadAnonymizeDocumentsResult({
                            anonymized_documents_ids: [anonymizedHtml.id]
                        }));
                        setLoading(false);
                    }
                } 
            } catch (error) {
                notification({
                    type: "error",
                    message: "Ошибка при скачивании файла"
                });
                setLoading(false);
            }
        }, 0);
    };

    const downloadOriginal = () => {
        if (openedDocument) {
            dispatch(Actions.Document.downloadOriginalDocument({documents_ids: [openedDocument.id]}));
        }
    };

    return (
        <Toolbar.Wrapper className={styles.wrapper}>
            <Toolbar.Col>
                <>
                    <BackLink 
                        documentId={openedDocument.id}
                        projectId={openedDocument.project_id}
                        anonymizedDocumentId={anonymizedHtml.id}
                        anonymizationRequestId={anonymizedHtml.anonymization_request_id}
                        addedEntities={addedEntities}
                        selectedMarkups={selectedMarkups}/>
                    <Toolbar.Title title={openedDocument.document_name} />
                </>
            </Toolbar.Col>
            <Toolbar.Col colWrapElements>
                {openedDocument.anonymization_type !== "pdf_anonymization" && (
                    <ViewModeSwitcher
                        addedEntities={addedEntities}
                        anonymizationRequestId={anonymizedHtml.anonymization_request_id}
                        anonymizedDocumentId={anonymizedHtml.id}
                        documentId={openedDocument.id}  
                        selectedMarkups={selectedMarkups}
                        settings={documentState.settings}/>
                )}
                <div className={styles.downloadButton}> 
                    <DropdownMenu
                        loading={loading}
                        downloadOriginal={downloadOriginal}
                        downloadResult={downloadResult}
                    />
                </div>
            </Toolbar.Col>
        </Toolbar.Wrapper>
    );
};

export default TopToolbar;
