import {call, put, race, take, cancel, fork, delay} from "redux-saga/effects";
import Actions from "@actions";
import {getAnonHtmlRequest} from "./GetAnonHtml";
import {Task} from "redux-saga";
import React from "react";
import {Api} from "@types";

const getAnonHtmlLoop = function*(...args: Parameters<typeof getAnonHtmlRequest>) {
    while (true) {
        const data:Api.UnboxCall<ReturnType<typeof getAnonHtmlRequest>> = yield call(getAnonHtmlRequest, ...args);
        if (data.anonymization_status > 100) {
            yield put(Actions.Anonymize._getAnonHtmlSuccess(data));
            break;
        }
        yield delay(5000);
    }
};

const AnonymizeWatchFlow = function*() {
    let forkTask:Task | null = null;
    while (true) {
        const actions = {
            start: Actions.Anonymize.anonymizeWatchStart,
            stop: Actions.Anonymize.anonymizeWatchStop
        };
        type start = ReturnType<typeof actions.start>;
        type stop = ReturnType<typeof actions.stop>;
        type action = {
            start?: start;
            stop?: stop;
        }
        
        const action:action = yield race({
            start: take(actions.start),
            stop: take(actions.stop)
        });
        if (forkTask) yield cancel(forkTask);
        if (action.start) {
            forkTask = yield fork(getAnonHtmlLoop, action.start.payload);
        }
    }
};

export default function* () {
    yield fork(AnonymizeWatchFlow);
}
