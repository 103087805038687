import React from "react";
import {takeLatest, call, put, delay} from "redux-saga/effects";
import Actions from "@actions";
import Api from "../../Api";
import {PayloadAction} from "@reduxjs/toolkit";
import {iActions} from "@redux/Document/types";
import useNotification from "@root/Hooks/useNotification/useNotification";

const GetAnonymizedDocuments = function* (action: PayloadAction<iActions.getAnonymizedDocuments>) {
    const {payload} = action;
    const notification = useNotification();
    let queryCount: number = 0;
    while (queryCount < 5) {
        try {
            const {data} = yield call(Api.Document.getAnonymizedDocuments, payload);
            yield put(Actions.Document._getAnonymizedDocumentsSuccess(data));
            payload.onSuccess && payload.onSuccess();
            break;
        } catch (error: any) {
            yield delay(1000);
            queryCount += 1;
            if (queryCount === 4) {
                yield put(Actions.Document._getAnonymizedDocumentsError());
                payload.onError && payload.onError();
                notification({
                    type: "error",
                    message: error.response.data.error 
                        ? error.response.data.error 
                        : "При получении списка анонимизированных документов произошла ошибка"
                });
            }
        }
    }
};

export default function* () {
    yield takeLatest(Actions.Document.getAnonymizedDocuments, GetAnonymizedDocuments);
}
