import Request from "../Request";
import {Document} from "./types";
import Endpoints from "./endpoints";

export default {
    searchInDocument: (params: Document.iSearchInDocument) => {
        return Request.post<Document.oSearchInDocument>(`${Endpoints.documents.searchInDocument}`, params);
    },
    getPdfDocument: (params: Document.iGetPdfDocument) => {
        return Request.get<Document.oGetPdfDocument>(
            Endpoints.documents.getPdfDocument.replace("{docId}", params.id),
            undefined,
            {
                responseType: "blob"
            });
    },
    deleteDocument: (params: Document.iDeleteDocument) => {
        const url =  Endpoints.documents.deleteDocument.replace("{docId}", params.documentID);
        return Request.delete(
            url
        );
    },
    downloadOriginalDocument: (params: Document.iDownloadOriginalDocument) => {
        const url = Endpoints.documents.downloadOriginalDocument;
        return Request.post(
            url,
            params,
            {
                responseType: "blob",
            }
        );
    },
    getAnonymizedDocuments: (params: Document.iGetAnonymizedDocuments) => {
        return Request.get<Document.oGetAnonymizedDocuments>(
            Endpoints.documents.getAnonymizedDocuments.replace("{docId}", params.document_id),
        );
    },
    deleteAnonymizedDocument: (params: Document.iDeleteAnonymizedDocument) => {
        return Request.delete(
            Endpoints.documents.deleteAnonymizedDocuments, null, {
                data: params
            }
        );
    }
};
