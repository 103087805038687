import React, {useState} from "react";
import {Modal,Button, FormItem, Select} from "@root/Components/Controls";
import {Form} from "antd";
import {useAppDispatch, useAppSelector} from "@root/Hooks";
import styles from "./AnonymizeModal.module.less";
import GridContainer from "@root/Components/GridContainer/GridContainer";
import {SelectValue} from "antd/lib/select";
import SelectWithSelectAll from "@root/Components/Controls/SelectWithSelectAll/SelectWithSelectAll";
import useGetHintContent from "./Hooks/useGetHintContent";
import Hint from "@root/Components/Hint/Hint";
import {Anonymization, Documents} from "@types";
import TemplateDescription from "@root/Pages/Lk/Templates/Components/TemplateDescription/TemplateDescription";
import Actions from "@actions";
import InfoIcon from "@root/Components/Hint/Icons/InfoIcon";

const {Option} = Select;

type AnonymizeDoProps = {
    anonymizeModalOpen: boolean,
    onCancelModal: () => void,
    documentsIds: Documents.Item["id"][],
    projectIds: number,
    loading?: boolean,
    onSuccessAction?: () => void,
    onErrorAction?: () => void,
}

const AnonymizeModal = (props: AnonymizeDoProps) => {
    const {
        anonymizeModalOpen,
        onCancelModal,
        documentsIds,
        projectIds,
        loading = false,
        onSuccessAction,
        onErrorAction
    } = props;
    const {const_replacementHint, configsHint, chosePipelineHint} = useGetHintContent();
    const [form] = Form.useForm();
    const [templateEntities, setTemplateEntities] = useState<Anonymization.Templates.Template | null>(null);
    const entities = useAppSelector((state) => state.Common.entities.data);
    const templates = useAppSelector((state) => state.Templates.templates.data);
    const dispatch = useAppDispatch();

    if (!entities || !templates) return null;

    const getTitle = () => {
        if (documentsIds.length === 1) return "Обезличивание документа";
        if (documentsIds.length > 1) return "Обезличивание документов";
        return "Обезличивание";
    };
    const onChangePipeline = (value: SelectValue) => {
        if (value === "entities") setTemplateEntities(null);
        if (value !== "entities") setTemplateEntities(templates.filter((template) => template.id === value)[0]);
    };

    const onFinish = () => {
        // TODO использовать соответствкующий Action для вызова саги на анонимизацию
        // + необходимо добавть статус документа тк сейчас есть возможность запустить повторный запрос (он упадет с 500)
        const config = {
            entities: form.getFieldValue("entities"),
            anonymization_type: form.getFieldValue("anon_type")
        };

        dispatch(Actions.Anonymize.anonymizeFile({
            documents_ids: documentsIds,
            ...config, 
            onSuccess: () => {
                form.resetFields();
                onSuccessAction && onSuccessAction();
            }
        }));
    };    
    
    return (
        <div>
            <Modal
                antProps={{
                    open: anonymizeModalOpen,
                    title: getTitle(),
                    footer: null,
                    width: 440,
                    onCancel: onCancelModal,
                    centered: true,
                }}>
                <GridContainer
                    className={styles.grid}>
                    <Form
                        layout="vertical"
                        onFinish={onFinish}
                        form={form}>
                        <div className={styles.info}>
                            <div
                                className={styles.buttonInfo}>
                                <InfoIcon />
                            </div>
                            Документ обезличить можно до 10 раз
                        </div>
                        <FormItem
                            required={false}
                            name="anon_type"
                            rules={[{
                                required: true,
                                message: "Выберите тип обезличивания"
                            }]}
                            label={
                                <div className={styles.label}>
                                Тип обезличивания
                                    <Hint title={() => const_replacementHint}/>
                                </div>}>
                            <Select
                                disabled={loading}
                                placeholder="Выберите тип обезличивания"
                                allowClear>
                                <Option
                                    key={"dict_anonymization"}
                                    value={"dict_anonymization"}>
                                    Замена из словаря
                                </Option>
                                <Option
                                    key={"const_anonymization"}
                                    value={"const_anonymization"}>
                                    Замена константами
                                </Option>
                                {/* TODO Убрать когда сделается пдф */}
                                {/* <Option
                                    key={"pdf_anonymization"}
                                    value={"pdf_anonymization"}>
                                    Закрашивание
                                </Option> */}
                            </Select>
                        </FormItem>
                        <FormItem
                            label={
                                <div className={styles.label}>
                                    Тип редактирования
                                    <Hint title={() => chosePipelineHint}/>
                                </div>}>
                            <Select
                                disabled={loading}
                                defaultValue={"entities"}
                                onChange={onChangePipeline}
                                options={[
                                    {
                                        label: "Сущности",
                                        options: [
                                            {label: "Отдельные сущности",
                                                value: "entities"},
                                        ],
                                    },
                                    {
                                        label: "Шаблоны",
                                        options: templates.map((item, index) => {
                                            return (
                                                {
                                                    label: item.title,
                                                    value: item.id
                                                }
                                            );
                                        }),
                                    },
                                ]}/>
                        </FormItem>
                        {
                            (templateEntities && templateEntities.description) && (
                                <FormItem                             
                                    label="Комментарий к шаблону">
                                    <TemplateDescription description={templateEntities.description} />
                                </FormItem>
                            )
                        }
                        <FormItem
                            required={false}
                            name="entities"
                            rules={[{
                                required: true,
                                message: "Выберите сущности"
                            }]}
                            label={
                                <div className={styles.label}>
                                    Сущности на обезличивание
                                    <Hint title={() => configsHint}/>
                                </div>}>
                            <SelectWithSelectAll 
                                disabled={loading}
                                initialValue={templateEntities?.entities ? templateEntities.entities : undefined}
                                templateMode={Boolean(templateEntities)}
                                setValue={(value) => form.setFieldsValue({["entities"]: value})}
                                options={entities}
                                treeSelectProps={{
                                    placeholder: "Выберите сущности"
                                }}/>
                        </FormItem>
                        <div className={styles.controlBlock}>
                            <div className={styles.buttons}>
                                <FormItem className={styles.lastFormItem}>
                                    <Button
                                        loading={loading}
                                        className={styles.backButton}
                                        type="stroke"
                                        size="large"
                                        onClick={onCancelModal}>
                                        Назад
                                    </Button>
                                </FormItem>
                                <FormItem className={styles.lastFormItem}>
                                    <Button
                                        loading={loading}
                                        className={styles.button}
                                        type="primary"
                                        size="large"
                                        htmlType="submit">
                                        Обезличить
                                    </Button>
                                </FormItem>
                            </div>
                        </div>
                    </Form>
                </GridContainer>
            </Modal>
        </div>
    );
};

export default AnonymizeModal;
